import api from "./Api";

async function getBlipChatConfigKey(botShortName: string, tenantUrl: string) {
  try {
    const response = await api(tenantUrl).get(
      `configurations/blip-chat/${botShortName}`
    );

    if (response.data.status === "failure") {
      console.error(response.data);
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const ConfigurationBlipChat = {
  getBlipChatConfigKey,
};
