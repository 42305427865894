import Menu from './components/menu';
import channelsList from './constants/channels.json';

import {
    MainContainer,
} from "./styles";

export const Channels = () => {
    return (
        <MainContainer>
            <Menu itens={channelsList} />
        </MainContainer >
    );
};