export class AppData {
  accountToken?: string;
  profile?: {
    name?: string;
    website?: string;
    email?: string;
    phone_number?: string;
  };
  routerData?: {
    accessKey?: string;
    shortName?: string;
    skillTransbordo: {
      accessKey?: string;
      shortName?: string;
    };
  };
  constructor(
    accountToken?: string,
    name?: string,
    website?: string,
    email?: string,
    phoneNumber?: string,
    routerAccessKey?: string,
    transhipmentAccessKey?: string,
    routerShortName?: string,
    transhipmentShortName?: string
  ) {
    this.accountToken = accountToken;
    this.profile = {
      name: name,
      website: website,
      email: email,
      phone_number: phoneNumber,
    };
    this.routerData = {
      accessKey: routerAccessKey,
      shortName: routerShortName,
      skillTransbordo: {
        accessKey: transhipmentAccessKey,
        shortName: transhipmentShortName,
      },
    };
  }
}
