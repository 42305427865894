import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import { mountKey } from "src/utils/mountKey";
import { BuildBlipChatUrl as _buildBlipChatUrl } from "src/services/blipChat/buildUrlBlipChat";
import { BuildActivateInstagramUrl as _buildActiveInstagramUrl } from "src/services/activateChannels/buildActivateInstagramUrl";
import Item from "./item";

interface IMenu {
  itens: {
    title: string;
    photo: string;
    linkActive: boolean;
    link: string;
  }[];
}

const Menu = ({ itens }: IMenu) => {
  const { routerData, userData, accountToken } = useAppContext();
  const [blipChatUrl, setBlipChatUrl] = useState("");
  const [activateInstagramUrl, setActivateInstagramUrl] = useState("");

  const routerKey =
    routerData.shortName && routerData.accessKey
      ? mountKey(routerData.shortName, routerData.accessKey)
      : "";

  useEffect(() => {
    try {
      if (accountToken && routerData.shortName) {
        _buildBlipChatUrl
          .buildBlipChatUrl(
            routerKey,
            userData.email!,
            accountToken,
            routerData.shortName
          )
          .then((value) => value)
          .then((resp) => {
            setBlipChatUrl(resp);
          });
        _buildActiveInstagramUrl
          .buildActivateInstagramUrl(
            routerKey,
            userData.email!,
            routerData.shortName
          )
          .then((value) => value)
          .then((resp) => {
            setActivateInstagramUrl(resp);
          });
      }
    } catch (error) {
      console.log("Invalid app data!");
    }
  }, [routerKey, userData, accountToken, routerData]);

  return (
    <bds-grid xxs="12" direction="column" justify-content="center">
      <bds-grid
        container
        direction="row"
        flex-wrap="wrap"
        justify-content="center"
      >
        {itens.map((item, index) => {
          return (
            <bds-grid margin="y-2" key={index}>
              <bds-paper>
                <Item
                  item={item}
                  blipChatUrl={blipChatUrl}
                  activateInstagramUrl={activateInstagramUrl}
                  key={index}
                ></Item>
              </bds-paper>
            </bds-grid>
          );
        })}
      </bds-grid>
    </bds-grid>
  );
};

export default Menu;
