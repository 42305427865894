import axios from "axios";
import { CurrentEnvironment } from "src/configs/Environment";

const api = (tenantUrl: string) => {
  const url =
    CurrentEnvironment === "production"
      ? "https://takeblipapps.blip.tools/api/v1"
      : "https://takeblipapps-hmg.blip.tools/api/v1";

  return axios.create({
    baseURL: url,
  });
};

export default api;
