import { ChannelsTracks } from "src/pages/channels/services/trackings/handleSendTrackings";
import "./styles.scss";
import { useChannelContext } from "src/contexts/ChannelsContext";
import ModalInstagram from "../../modal";

interface IItem {
  item: {
    title: string;
    photo: string;
    linkActive: boolean;
    link: string;
  };
  blipChatUrl: string;
  activateInstagramUrl: string;
}

const Item = ({ item, blipChatUrl, activateInstagramUrl }: IItem) => {
  const channelsTracks = new ChannelsTracks();

  const { setOpenInstagramModal } = useChannelContext();

  function redirect() {
    channelsTracks.sendTrackClickButtonChannelsActivated({
      channel: item.title,
    });
    switch (item.title) {
      case "Instagram":
        setOpenInstagramModal(true);
        channelsTracks.sendTrackShowModalActivateChannel({
          channel: item.title,
        });
        break;
      default:
        window.open(item.link, "_blank");
    }
  }

  function redirectButtonChannelsTest() {
    window.open(blipChatUrl, "_blank");
    channelsTracks.sendTrackClickButtonChannelsTested({ channel: item.title });
  }

  return (
    <div className="menu-boxes__item">
      <div className="menu-boxes__imagem">
        <img src={item.photo} alt={item.title} />
        <bds-typo>{item.title}</bds-typo>
      </div>

      {item.linkActive ? (
        <div className="menu-boxes__buttom">
          <bds-button
            type="button"
            onClick={() => redirectButtonChannelsTest()}
          >
            Testar canal
          </bds-button>
        </div>
      ) : (
        <div className="menu-boxes__buttom">
          <bds-button type="button" variant="ghost" onClick={() => redirect()}>
            Ativar canal
          </bds-button>
          {item.title === "Instagram" && (
            <ModalInstagram
              title={item.title}
              activateInstagramUrl={activateInstagramUrl}
            ></ModalInstagram>
          )}
        </div>
      )}
    </div>
  );
};

export default Item;
