import React, { SetStateAction, createContext, useState } from "react";

type ChannelsContextData = {
  openInstagramModal: boolean;
  setOpenInstagramModal: React.Dispatch<SetStateAction<boolean>>;
  closeInstagramModal: string;
  setCloseInstagramModal: React.Dispatch<SetStateAction<string>>;
};

export const ChannelsContext = createContext<ChannelsContextData>(
  {} as ChannelsContextData
);

export const ChannelsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [openInstagramModal, setOpenInstagramModal] = useState(false);
  const [closeInstagramModal, setCloseInstagramModal] = useState("");

  return (
    <ChannelsContext.Provider
      value={{
        openInstagramModal,
        setOpenInstagramModal,
        closeInstagramModal,
        setCloseInstagramModal,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
};

export function useChannelContext(): ChannelsContextData {
  const context = React.useContext(ChannelsContext);

  if (!context) {
    throw new Error(
      "use channels context must be used within an ChannelsProvider"
    );
  }

  return context;
}
