import { CurrentEnvironment } from "src/configs/Environment";
import { ConfigurationService as _configurationService } from "../configurationService/ConfigurationService";
import { ConfigurationBlipChat as _configurationBlipChat } from "./BlipChat";

function urlBase() {
  const isHmg = CurrentEnvironment === "production";
  return isHmg ? "chat.blip.ai/" : "hmg-chat.blip.ai";
}

async function getAccountInformation(routerKey: string, mail: string) {
  let configurations = await _configurationService.GetAcountInformation(
    routerKey,
    mail
  );

  if (configurations === undefined) {
    configurations = { tenantId: "" };
  }

  return {
    tenandId: configurations.resource.extras.tenantId,
  };
}

async function getBlipChatAppKey(botShortName: string, tenantUrl: string) {
  let configKey = await _configurationBlipChat.getBlipChatConfigKey(
    botShortName,
    tenantUrl
  );

  return { appKey: configKey.appKey };
}

async function buildBlipChatUrl(
  routerKey: string,
  mail: string,
  accountToken: string,
  shortName: string
) {
  const accountInformation = await getAccountInformation(routerKey, mail);
  const urlbase = urlBase();
  const blipChatConfig = await getBlipChatAppKey(
    shortName,
    accountInformation.tenandId
  );

  const blipChatUrl = `https://${accountInformation.tenandId}.${urlbase}?appKey=${blipChatConfig.appKey}`;

  return blipChatUrl;
}

export const BuildBlipChatUrl = {
  buildBlipChatUrl,
};
