import { SendTrackProps } from "src/types/SendTrackProps";

import { AnalyticsService as _analyticsService } from "src/services/analyticsService/AnalyticsService";

export class ChannelsTracks {
  sendTrackStartPlugin = async () => {
    _analyticsService.Track("blippacks-managment-channels-opened");
  };

  sendTrackClickButtonChannelsTested = async ({ channel }: SendTrackProps) => {
    _analyticsService.Track("blippacks-management-general-channels-tested", {
      channel,
    });
  };

  sendTrackClickButtonChannelsActivated = async ({
    channel,
  }: SendTrackProps) => {
    _analyticsService.Track("blippacks-management-general-channels-activated", {
      channel,
    });
  };

  sendTrackShowModalActivateChannel = async ({ channel }: SendTrackProps) => {
    _analyticsService.Track("blippacks-management-channels-modal-activate", {
      channel,
    });
  };

  sendTrackModalConfirmActivateChannel = async ({
    channel,
  }: SendTrackProps) => {
    _analyticsService.Track(
      "blippacks-management-channels-modal-activate-confirm",
      {
        channel,
      }
    );
  };

  sendTrackModalCloseActivateChannel = async ({
    channel,
    button,
  }: SendTrackProps) => {
    _analyticsService.Track(
      "blippacks-management-channels-modal-activate-close",
      {
        channel,
        button,
      }
    );
  };
}
