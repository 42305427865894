import { Buffer } from "buffer";
import parser from "query-string";
import { _logService } from "../../configs/LogConfig";
import { Json } from "src/utils/Json";
import { AppData } from "./models/AppData";

const _logger = _logService.getChildCategory("QueryString");

function Parse(): object {
  let queryString: string = window.location.search;

  _logger.info(`Parsing "${queryString}"...`);

  let result = parser.parse(queryString);

  _logger.info(`Parsed "${queryString} with success"`);
  _logger.debug(Json.StringifyFormat(result), "Parsed object");

  return result;
}

function TryGetAppData(): AppData {
  try {
    let parsedQueryString = Parse();

    type queryStringType = keyof typeof parsedQueryString;

    const searchKey = "AppData";

    _logger.info("Parsing App Data");

    let appDataKey = Object.keys(parsedQueryString).find(
      (key) => key.toLowerCase() === searchKey.toLowerCase()
    );

    const appDataProperty = appDataKey as queryStringType;

    let value = parsedQueryString[appDataProperty];

    _logger.debug(value, "App Data as Base64");

    let parsedValue = Buffer.from(value, "base64").toString("utf8");

    _logger.debug(parsedValue, "App Data as Text");

    let parsedJson = JSON.parse(parsedValue);

    _logger.debug(parsedJson.profile.name, "App profile name");

    let result = new AppData(
      parsedJson.accountToken,
      parsedJson.profile.name,
      parsedJson.profile.website,
      parsedJson.profile.email,
      parsedJson.profile.phone_number,
      parsedJson.routerData.accessKey,
      parsedJson.routerData.skillTransbordo.accessKey,
      parsedJson.routerData.shortName,
      parsedJson.routerData.skillTransbordo.shortName
    );

    _logger.debug(Json.StringifyFormat(result), "App Data casted as object");
    _logger.info("App data retrived with success!");

    return result;
  } catch (err) {
    throw new Error(`The informed value in AppData is not a valid Base64.`);
  }
}

export const QueryStringService = {
  Parse,
  TryGetAppData,
};
