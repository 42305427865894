import styled from "styled-components";

export const HorizontalLine = styled.div`
  border-top: 1px solid #d2dfe6;
  margin-top: 1%;
  margin-bottom: 1%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  margin-bottom: 70px;
  margin-top: 30px;
`;

export const TabsPanelContainer = styled.div`
  border: 1px solid #d3dfe6;
  border-radius: 9px;
`;

export const AttendanceHoursContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const ColumnContainer = styled.div`
  padding: 25px 50px 25px 50px;
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  margin-bottom: 24px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 30px;
`;

export const HeaderAttendanceQueueContainer = styled.div`
  display: flex;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SingleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 32px;
  width: 140px;
`;
