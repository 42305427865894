import { CurrentEnvironment } from "src/configs/Environment";
import { ConfigurationService as _configurationService } from "../configurationService/ConfigurationService";

function urlBase() {
  const isHmg = CurrentEnvironment === "production";
  return isHmg
    ? "blip.ai/application/detail/"
    : "hmg.blip.ai/application/detail/";
}

async function getAccountInformation(routerKey: string, mail: string) {
  let configurations = await _configurationService.GetAcountInformation(
    routerKey,
    mail
  );

  if (configurations === undefined) {
    configurations = { tenantId: "" };
  }

  return {
    tenandId: configurations.resource.extras.tenantId,
  };
}

async function buildActivateInstagramUrl(
  routerKey: string,
  mail: string,
  shortName: string
) {
  const accountInformation = await getAccountInformation(routerKey, mail);
  const urlbase = urlBase();

  const activateInstagramUrl = `https://${accountInformation.tenandId}.${urlbase}${shortName}/channels/instagram`;

  return activateInstagramUrl;
}

export const BuildActivateInstagramUrl = {
  buildActivateInstagramUrl,
};
