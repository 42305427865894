import instagramActivationModalSVG from "src/assets/images/instagramActivationModal.svg";
import { useChannelContext } from "src/contexts/ChannelsContext";
import { ChannelsTracks } from "src/pages/channels/services/trackings/handleSendTrackings";
import "./styles.scss";
import { useEffect, useRef } from "react";
import modalTexts from "src/texts/modalTexts.json";

interface IModalInstagram {
  title: string;
  activateInstagramUrl: string;
}
const ModalInstagram = ({ title, activateInstagramUrl }: IModalInstagram) => {
  const channelsTracks = new ChannelsTracks();

  const {
    openInstagramModal,
    setOpenInstagramModal,
    closeInstagramModal,
    setCloseInstagramModal,
  } = useChannelContext();

  function toggleModal(button: string) {
    setOpenInstagramModal(false);
    setCloseInstagramModal(button);
  }

  function redirect() {
    window.open(activateInstagramUrl, "_blank");
    channelsTracks.sendTrackModalConfirmActivateChannel({ channel: title });

    toggleModal("");
  }

  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  useEffect(() => {
    if (
      !openInstagramModal &&
      (closeInstagramModal === "Voltar" || closeInstagramModal === "X")
    ) {
      channelsTracks.sendTrackModalCloseActivateChannel({
        channel: title,
        button: closeInstagramModal,
      });
    }
  }, [openInstagramModal]);

  useEffect(() => {
    modalRef.current?.addEventListener("bdsModalChanged", (event: any) => {
      if (event.detail.modalStatus === "closed") {
        toggleModal("X");
      }
    });
  }, []);

  return (
    <bds-modal
      ref={modalRef}
      open={openInstagramModal}
      closeButton={false}
      size="dynamic"
    >
      <div className="modal">
        <div className="modal-image">
          <img
            src={instagramActivationModalSVG}
            alt="Instagram activation modal"
          />
        </div>
        <div className="modal-text">
          <bds-typo variant="fs-20" bold="bold" margin>
            {modalTexts.instagram.title}
          </bds-typo>
          <bds-typo variant="fs-14" bold="regular" margin>
            {modalTexts.instagram.info1a}{" "}
            <strong>{modalTexts.instagram.info1b}</strong>
          </bds-typo>
          <br />
          <bds-typo variant="fs-14" bold="regular" margin>
            {modalTexts.instagram.info2}
          </bds-typo>
          <br />
          <bds-typo variant="fs-14" bold="regular" margin>
            {modalTexts.instagram.info3}
          </bds-typo>
        </div>
      </div>

      <bds-modal-action>
        <div className="modal-button">
          <bds-button variant="secondary" onClick={() => toggleModal("Voltar")}>
            {modalTexts.instagram.button.cancel}
          </bds-button>
          <bds-button style={{ marginLeft: 16 }} onClick={() => redirect()}>
            {modalTexts.instagram.button.redirect}
          </bds-button>
        </div>
      </bds-modal-action>
    </bds-modal>
  );
};

export default ModalInstagram;
